<template>
  <CCol lg="9">
    <CCard>
      <CCardHeader class="bg-yellow"
        ><h3>แบบบันทึกคะแนนพฤติกรรม</h3></CCardHeader
      >
      <form id="formCreateAbsense" v-on:submit.prevent="sendData" method="POST">
        <CCardBody>
          <h3 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            ข้อมูลพื้นฐาน
          </h3>
          <CRow>
            <CCol lg="6">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสผู้แจ้ง
                </label>
                <input
                  type="text"
                  name="userId"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readonly
                  v-model="userData.user_id"
                />
              </div>
            </CCol>
            <CCol lg="6" v-if="userData"
              ><div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสโรงเรียน
                </label>
                <input
                  type="text"
                  name="txtSchoolId"
                  class="form-control"
                  readonly
                  :value="userData.user_address"
                /></div
            ></CCol>
            <CCol lg="6">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  วันที่
                </label>
                <datepicker
                  name="txtDate"
                  input-class="form-control bg-white"
                  :required="true"
                  format="yyyy-MM-dd"
                  placeholder="วันที่จัดกิจกรรม"
                  v-model="txtDate"
                ></datepicker>
              </div>
            </CCol>
            <CCol lg="12">
              <SltCriteria />
            </CCol>
            <CCol lg="6" v-if="selectedCiteria">
              <div
                class="relative w-full mb-3"
                v-if="selectedCiteria.tag == 'class'"
              >
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  เวลา (ชั่วโมง/ คาบ)
                </label>
                <select name="sltHours" class="form-control">
                  <option value="">-----เลือก-----</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </CCol>
            <CCol lg="6">
              <div class="relative w-full mb-3" v-if="semesterInfo">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ภาคเรียน
                </label>
                <v-select :options="semesterInfo" v-model="selectedSemester">
                  <template #search="{ attributes, events }">
                    <input
                      class="from-control ui-input-text"
                      v-bind="attributes"
                      v-on="events"
                      placeholder=""
                    />
                  </template>
                </v-select>
              </div>
              <div v-else class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </CCol>
            <CCol lg="12">
              <SltSubject />
            </CCol>
          </CRow>
          <h3 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            ข้อมูลนักเรียน
          </h3>
          <CRow>
            <CCol lg="12">
              <SltStudent />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter class="text-right">
          <button class="btn btn-yellow" :disabled="disableBtn">
            <span v-if="disableBtn == false">ส่ง</span
            ><span v-else>Sending...</span>
          </button>
        </CCardFooter>
      </form>
    </CCard>

    <!--MODAL SECTION---------------------------------------------------------------------------->
    <form
      id="formCreateCriteria"
      v-on:submit.prevent="addCriteria"
      method="POST"
    >
      <CModal
        :show.sync="modalAddCriteria"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสโรงเรียน
              </label>
              <input
                type="text"
                name="schoolCode"
                class="form-control"
                readonly
                :value="userData.user_address"
              /></div
          ></CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสเกณฑ์
              </label>
              <input
                type="text"
                name="criteriaNo"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                v-model="criteriaRow"
                readonly
                required
              />
            </div>
          </CCol>
          <SltSubject />
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                คะแนน
              </label>
              <input
                type="number"
                name="criteriaScore"
                class="form-control"
                required
              /></div
          ></CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">เพิ่มเกณฑ์คะแนน</h6>
          <CButtonClose @click="modalAddCriteria = false" class="text-white" />
        </template>
        <template #footer>
          <button type="submit" class="btn btn-yellow">Save</button>
          <button class="btn btn-black" @click="modalAddCriteria = false">
            Discard
          </button>
        </template>
      </CModal>
    </form>
  </CCol>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SltStudent from "./SltStudent";
import SltCriteria from "./SltCriteria";
import SltSubject from "./SltSubject.vue";
export default {
  components: {
    Datepicker,
    vSelect,
    SltStudent,
    SltCriteria,
    SltSubject,
  },
  data() {
    return {
      semesterInfo: null,
      subjectInfo: null,
      studentInfo: null,
      citeriaInfo: null,
      criteriaRow: null,
      userData: null,
      selectedSemester: "",
      selectedSubject: null,
      selectedStudent: null,
      selectedCiteria: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      modalAddCriteria: false,
      newCiteria: null,
      darkModal: false,
      disableBtn: false,
      txtDate: new Date(),
    };
  },
  mounted() {
    //SET USER DATA
    this.userData = this.$cookies.get("user");
    //FETCH SEMESTER DATA
    this.axios
      .get(
        this.$hostUrl + "php_action/semesterAPI.php?txtAction=get&qSemester=all"
      )
      .then((response) => {
        this.semesterInfo = response.data.mainData;
        //console.log(this.semesterInfo);
      })
      .finally(() => {});
    //FETCH SUBJECT DATA
    this.axios
      .get(
        this.$hostUrl + "php_action/subjectAPI.php?txtAction=get&qSubject=all"
      )
      .then((response) => {
        this.subjectInfo = response.data.mainData;
        //console.log(response.data);
      })
      .finally(() => {});
    //FETCH STUDENT DATA
    this.axios
      .get(
        this.$hostUrl +
          "php_action/userAPI.php?txtAction=get&qStd=all&schoolId=" +
          this.userData.user_address
      )
      .then((response) => {
        this.studentInfo = response.data.mainData;
        //console.log(response.data);
      })
      .finally(() => {});
  },
  methods: {
    sendData() {
      if (this.selectedSemester.length < 1) {
        alert("กรุณาเลือกภาคเรียน");
        return false;
      }
      this.disableBtn = true;
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append(
        "selectedSubject",
        this.$store.state.selectedSubject.code
      );
      formData.append("txtReason", this.$store.state.selectedCriteria.code);
      let jsonArr = JSON.stringify(this.$store.state.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.createState == "created") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            this.disableBtn = false;
          }
        });
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },

    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
    addCriteria(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createCriteria");
      this.axios
        .post(this.$hostUrl + "php_action/criteriaAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "success") {
            alert("บันทึกข้อมูลสำเร็จ");
            location.reload();
          } else {
            alert(response.data.createState);
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
